if (window.matchMedia("(min-width: 1199px)").matches) {
  $(window).on("scroll", function() {
    var sticky = $('.page-header'),
      scroll = $(window).scrollTop(),
      container = $('.page-main');

    if (scroll >= 48) {
      sticky.addClass('page-header--sticky');
      container.addClass('page-main--sticky');
    } else {
      sticky.removeClass('page-header--sticky');
      container.removeClass('page-main--sticky');
    }
  });
}
