if (window.matchMedia(`(max-width: 1199px)`).matches) {
  $(`.main-nav__toggle`).on('click', () => {
    $(`.main-nav__toggle`).toggleClass(`main-nav__toggle--active`);
    $(`.overlay`).toggleClass(`overlay--active`);
    $(`.main-nav`).toggleClass(`main-nav--active`);

    if ($(`.page-header__makes-btn`).is(`.page-header__makes-btn--active`)) {
      $(`.page-header__makes-btn`).removeClass(`page-header__makes-btn--active`);
    }

    if ($(`body`).is(`.body--fixed`)) {
      $(`body`).removeClass(`body--fixed`);
    } else {
      $(`body`).addClass(`body--fixed`);
    }

    $(`.modal-makes`).removeClass(`modal-makes--active`);
    $(`.modal-search`).removeClass(`modal-search--active`);
  });

  $(`.site-list__link--group`).on('click', function () {
    $(this).closest('.site-list__item--group').find('.site-list__group-list').slideToggle();
  });

  $(`.overlay`).on('click', () => {
    $(`.main-nav__toggle`).toggleClass(`main-nav__toggle--active`);
    $(`.overlay`).toggleClass(`overlay--active`);
    $(`.main-nav`).toggleClass(`main-nav--active`);
    $(`body`).removeClass(`body--fixed`);

    $(`.modal-makes`).removeClass(`modal-makes--active`);
  });
}
