const elementRotate = (element) => {
  return element.style.transform = "rotate(" + window.pageYOffset/30 + "deg)";
};

const scrollRotate = () => {
  let itemsToRotate = document.querySelectorAll('.compare__icon-decor');
  let itemsToRotateCircles = document.querySelectorAll('.benefits__circle');
  itemsToRotate.forEach(element => elementRotate(element));
};

window.onscroll = function () {
  scrollRotate();
};
