import "jquery";

const lazyPicture = document.querySelector('.lazyload');
const popupLink = document.querySelector('.popup--link');
const map = document.querySelector('.map');

if (lazyPicture) {
  import(/* webpackChunkName: "lazysizes" */ 'lazysizes').then(module => module.init());
}

if (popupLink) {
  import(/* webpackChunkName: "popup" */ './components/popup.js').then(module => module.init());
}

if (map) {
  import(/* webpackChunkName: "popup" */ './components/map.js').then(module => module.init());
}

import "./components/header.js";
import "./components/main-nav.js";

import "jquery.maskedinput/src/jquery.maskedinput.js";
import "./components/mask.js";

import "./components/svg.js";
import "./components/aos.js";
import "./components/animate.js";
import "./components/form.js";
