import Vivus from "vivus";

// new Vivus('svg-line', {duration: 50});

// new Vivus('svg-line-mobile', {duration: 150});

// new Vivus('svg-circle', {duration: 150});

// new Vivus('svg-circle-small', {duration: 150});

