$(`.form__body`).submit(function (event) {
  event.preventDefault();
  var th = $(this);
  var data = th.serialize();
  th.find(`input,textarea,select,button`).attr(`disabled`, true);
  $.ajax({
    url: `send.php`,
    type: `POST`,
    dataType: `json`,
    data: data,
    success: function (response) {
      th.find(`input,textarea,select,button`).attr(`disabled`, false);
      if (response.success) {
        th[0].reset();
        window.location.href= `/thanks.html`;
      } else {
        if(response.field){
          th.find(`input[name="`+ response.field +`"]`).parent().addClass(`form__input-wrapper--error`);
          th.find(`input[name="`+ response.field +`"]`).parent().prepend(`<span style="color: #E40A38;font-size: 10px;">`+response.msg+`</span>`);
        }else{
          alert(response.msg);
        }
      }

    }
  })
});